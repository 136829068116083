import React from 'react';
import './Event.css';
import evenement from '../Emplacements/Evenement.json'
import getplanning from '../Outils/getplanning';

const ladate = new Date();

const mois = ["janvier","février","mars","avril","mai","juin","juillet","août","septembre","octobre","novembre","decembre"];
const période = mois[ladate.getMonth()]

console.log(evenement)

const Event= () =>{
  return (
    <>
    <div id='Backevent'>

      <div id='PGevent'>
      </div>

      <div id="Event">
        <div id='Privé'>
          <div className='Titreevent'>
            <p>Un évènement en vue?</p>
          </div>

          <div className='Texte'>
            Privatisez le food truck <span style={{fontWeight: 'bold'}}>Purée que c'est bon!</span> pour un de vos évènements!
            Nous définirons ensemble un assortiment de parmentiers pour régaler vos invités.
            Nous pouvons aussi vous proposer des entrées sous forme de tartares (canard, poisson ou végé).
          </div>
          <div id='Conditions'>
            <div className='STevent'>
              Á prévoir :
            </div>
            <div className='Texte'>
              - Minimum de 30 personnes,<br/>
              - Frais de déplacement 2€/km aller et retour,<br/>
              - Privatisation<br/>
              - Un accès à l'électricité,<br/>
            </div>
          </div>

        </div>
        <div id='Publique'>
          <div className='Titreevent'>
            <p>Retrouvez-nous sur des évènements publics!</p>
          </div>

          <div className='Texte'>
            Profitez d'un bon parmentier en musique ou autre activité culturelle.<br/>
            Voici le planning pour le mois de {période} : <br/>
            Aucun évènement n'est prévu pour le moment.
            
            {/* <div id='Planning'>
              {getplanning()}
            </div> */}

          </div>

        </div>
      </div>
      <div id='PDevent'>
      </div>

    </div>
    </>




  );
}
export default Event;