import React from 'react';
import './Contact.css';
import insta from '../../Images/InstaC2.png';
import facebook from '../../Images/FacebookC2.png';


const Contact= () =>{
  return (
    <>
    <div id='Contact'>
      <div id='Bandeaucontact'>
          <div id='Infocontact'>
            <div className='Titrecontact'>
              Informations de contact
            </div>
            <div>
                Mail : info@pureequecestbon.be
            </div>
            <div>
                Tel : +32474076820
            </div>

            <div id='Iconecontact'>
              <a href="https://www.instagram.com/pureequecestbon" target="_blank" rel='noreferrer'>
                  <img src={insta} alt="Insta" />
              </a>

              <a href="https://www.facebook.com/Pureequecestbon" target="_blank" rel='noreferrer'>
                  <img src={facebook} alt="Facebook" />
              </a>
            </div>
        </div>
      </div>
    </div>
    </>




  );
}
export default Contact;