import React from 'react';
import './Menu.css';
import trioparmentier from '../../Images/Trioparmentiers.png'
import pureejaune from '../../Images/Pureejaune.png'
import pureeorange from '../../Images/Pureeorange.png'
import pureeverte from '../../Images/Pureeverte.png'
import pureebordeau from '../../Images/Pureebordeau.png'
import cochon from '../../Images/CochonN.png'
import vache from '../../Images/VacheN.png'
import canard from '../../Images/CanardN.png'
import poisson from '../../Images/PoissonN.png'
import plante from '../../Images/PlanteN.png'



const Menu= () =>{
  return (
    <>
    <div id='Backmenu'>  
      
      <div id='PGmenu'>
      </div>

      <div id='MENU'>
        
        <div id='Entêtemenu'>
          <div className='Titremenu'>
            Compose ton parmentier
          </div>
          {/* <div className='Soustitremenu'>12€</div> */}
          <div className='Imagemenu'>
            <img src={trioparmentier} alt="Trioparmentier" />
          </div>
        </div>

        <div id='CHXbase'>
          <div className='Soustitremenu'>
            Choisis ton effiloché<br/>
          </div>

          <div id='Base'>
            <div className='imgmenu'>
              <img src={vache} width="60%" alt="Canard" /><br/>
              <div>Boeuf</div>
              <div> bourguigon</div>
            </div>
            <div className='imgmenu'>
              <img src={cochon} width="60%" alt="Poisson" /><br/>
              <div>Pulled</div>
              <div>pork</div>
            </div>
            <div className='imgmenu'>
              <img src={plante} width="60%" alt="Végétarien" /><br/>
              <div>Champignons,</div>
              <div>noix et tomates</div>
            </div>
            <div className='imgmenu'>
              <img src={plante} width="95%%" alt="Végétarien" /><br/>
              <div>Curry de,</div>
              <div>poirreaux</div>
            </div>
          </div>
        </div>

        <div id='CHXpuree'>
          <div className='Soustitremenu'>
            Choisis ta purée<br/>
          </div>

          <div id='Purée'>
            <div className='imgmenu'>
              <img src={pureejaune} alt="Pureejaune" /><br/>
              Patates
            </div>
            <div className='imgmenu'>
              <img src={pureeorange} alt="Pureeorange" /><br/>
              Potimarron & Butternut
            </div>
            {/* <div className='imgmenu'>
              <img src={pureebordeau} alt="Pureebordeau" /><br/>
              Haricots rouges
            </div> */}
            <div className='imgmenu'>
              <img src={pureeverte} alt="Pureeverte" /><br/>
              Pois Cassés
            </div>
          </div>
        </div>
        {/* <div id='Prix'>
          <div className='Portion'>
            1 portion :<br/>
            6€ (150g)
          </div>
          <div className='Portion'>
            2 portions :<br/>
            11€ (300g)
          </div>
          <div className='Portion'>
            3 portions :<br/>
            16€ (450g)
          </div>
        </div> */}
        
      </div>

        <div id='PDmenu'>
        </div>


    </div>

    </>




  );
}
export default Menu;