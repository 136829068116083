import React from 'react';
// import {  Link } from "react-router-dom";
import './Home.css';
import tour from  '../../Images/Tourparm.png'
import insta from '../../Images/InstaC1.png';
import facebook from '../../Images/FacebookC1.png';
import uber from '../../Images/Ubereats.png';
import proxi from '../../Images/Proxideal.png';
import takeaway from '../../Images/Takeaway.png';

const Home= () =>{
    return (
      <>
      <div id='Prehome'>

        <div id='Bandeauprehome'>
          
          {/* <div id='Pict'>
          <img src={tour} width="60%" alt="Tour parmentier" />
          </div> */}


          <div id='Infoprehome'>
              
              <div id='Marque'>
                <div>
                  Purée que c'est bon!
                </div>
                <div>
                  sillonne Bruxelles
                </div>
              </div>


                <p><div className='Titrehome'>Cette semaine retrouvez-nous :</div></p>
              
              
              {/* <p>  
                <div>Notre camion ayant besoin de réparations,</div>
                <div>nous ne sommes pas en mesure</div>
                <div>d'assurer nos emplacements</div>
              </p> */}
              
             <p>  Cette semaine nous aurons les mains dans le cambouis, c'est l'heure du controle technique</p> 
              
              {/* <p>  
                <div>Mardi : Métro Trone</div>
                <div>de 11h30 à 13h30</div>
              </p> */}
              {/* <p>
                <div>Mercredi : Place Jean Rey</div>
                <div>de 11h30 à 14h00</div>
              </p> */}
              {/* <p>
                <div>Jeudi: Parvis de Saint Gilles / Place Marie Janson</div>
                <div>de 11h30 à 14h00</div>
                <div>et de 18h00 à 21h00</div>
              </p> */}
              {/* <p>
                <div>Vendredi : Jardin de Jette</div>
                <div>de 18h00 à 20h00</div>
              </p> */}
                
              {/* <div>Pour se faire, rien de plus simple!</div>
              <div>Envoyer un mail à info@pureequecestbon.be</div>
              <div>avec votre commande avant mecredi soir minuit.</div>

              <p>
                <div className='Titrehome'>Commandez vos parmentiers prêts à manger</div>
                <div>Commandez sur les plateformes suivantes tous les samedi soir à partir de 18h30 :</div>
              </p>
              <div id='Livraison'>
                <a href='https://www.ubereats.com/be/store/puree-que-cest-bon/CXJWl7xmWcijMGPlIHkUtg?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJydXhlbGxlcyUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpaMmpIYy0ya3cwY1Jwd0p6ZUdZNmk4RSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1MC44NDc2NDI0JTJDJTIybG9uZ2l0dWRlJTIyJTNBNC4zNTcxNjk2JTdE&ps=1' target="_blank" rel='noreferrer'>
                  <img src={uber} alt="Ubereats" />
                </a>
                <a href='https://www.takeaway.com/be-fr/menu/puree-que-cest-bon' target="_blank" rel='noreferrer'>
                  <img src={takeaway} alt="Takeaway" />
                </a>
                <a href='https://www.proxideal.eu/?gclid=Cj0KCQjwyYKUBhDJARIsAMj9lkGMoag47Trf0VCAWeuv6CIRtF_CLQa3AR843t_7GkL2P-LjdlqrxaIaAlZLEALw_wcB' target="_blank" rel='noreferrer'>
                  <img src={proxi} alt="Proxideal" />
                </a>
              </div> */}
              
              
              <p>Suivez-nous pour ne pas nous rater :</p>
                      <div id='Iconeprehome'>
                          <a href="https://www.instagram.com/pureequecestbon" target="_blank" rel='noreferrer'>
                              <img src={insta} alt="Insta" />
                          </a>

                          <a href="https://www.facebook.com/Pureequecestbon" target="_blank" rel='noreferrer'>
                              <img src={facebook} alt="Facebook" />
                          </a>
                      </div>
          </div>
        </div>
      </div>
  
      </>
  
    );
  }
  export default Home;