import React, { useState}  from 'react';
import {  Link } from "react-router-dom";
import Windowsize from '../Outils/Windowsize'
import './Navbar.css';
import camion from '../../Images/Camion.png'
import canard from '../../Images/CanardN.png'
import poisson from '../../Images/PoissonN.png'
import plante from '../../Images/PlanteN.png'


var boutonstyle;

const Navbar= () =>{

    const [WinV,WinH]=Windowsize();
    
    const Navtextstyle ={
        marque : WinH/28,
        titre : WinH/35,
        imgca: WinH/34,
        imgpo: WinH/28,
        imgpl: WinH/34,
    }


    if (WinH<400) {
        boutonstyle ={
            height: WinH/6,
            width: WinH/6,
            camion: WinH/15,
            borderRadius: WinH/6,
            fontSize: WinH/25,
        }
        }
    else if (WinH<800) {
        boutonstyle ={
            height: WinH/8,
            width: WinH/8,
            camion: WinH/15,
            borderRadius: WinH/8,
            fontSize: WinH/28,
        }
    }
    else {
        boutonstyle ={
            height: WinH/8,
            width: WinH/8,
            camion: WinH/15,
            borderRadius: WinH/8,
            fontSize: WinH/28,
        }
    }


    const [position, setposition]=useState(1);


  return (
    <>
    <div id="TETE">
        
        <div className="ZONE">
            <Link to="/">
                <button onClick={()=>setposition(1)} id='Bhome'>
                    Purée<br/>
                    que c'est bon !
                </button>
            </Link>
        </div>
        
        <div className="ZONE">
            Maître parmentier
        </div>

        <div className="ZONE">
                <img src={canard} style={{width:Navtextstyle.imgca}} alt="Canard" />
                <img src={poisson} style={{width:Navtextstyle.imgpo}} alt="Poisson"/>
                <img src={plante} style={{width:Navtextstyle.imgpl}} alt="Plante"/>
        </div>

    </div>

    <div id="Bandeau_nav">
        <div className='Menu'>
            <Link to="/">
                    <button onClick={()=>setposition(1)}  style={boutonstyle} className='Bouton' >
                    OÚ?
                    </button>
            </Link>
            {position===1?<img src={camion} style={{width:boutonstyle.camion}} alt="Camion"></img> :  ""}
        </div>
        <div className='Menu'>
            <Link to="/menu">
                    <button onClick={()=>setposition(2)} style={boutonstyle} className='Bouton' >
                    MENU
                    </button>
            </Link>
            {position===2?<img src={camion} style={{width:boutonstyle.camion}} alt="Camion"></img> :  ""}
        </div>
        <div className='Menu'>
            <Link to="/apropos">
                    <button onClick={()=>setposition(3)} style={boutonstyle} className='Bouton' >
                    À PROPOS
                    </button>
            </Link>
            {position===3?<img src={camion} style={{width:boutonstyle.camion}} alt="Camion"></img> :  ""}
        </div>
        <div className='Menu'>
            <Link to="/event">
                    <button onClick={()=>setposition(4)} style={boutonstyle} className='Bouton' >
                    EVENT
                    </button>
            </Link>
            {position===4?<img src={camion} style={{width:boutonstyle.camion}} alt="Camion"></img> :  ""}
        </div>
        <div className='Menu'>
            <Link to="/contact">
                    <button onClick={()=>setposition(5)} style={boutonstyle} className='Bouton' >
                    CONTACT
                    </button>
            </Link>
            {position===5?<img src={camion} style={{width:boutonstyle.camion}} alt="Camion"></img> :  ""}
        </div>
    </div>

    </>

  );
}
export default Navbar;