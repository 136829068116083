import React from 'react';
import './Apropos.css';
import bet from '../../Images/DSCBET.jpg';
import pdt from '../../Images/DSCPDT.jpg';
import ptm from '../../Images/DSCPTM.jpg';
import augustep from '../../Images/AugusteP.png';
import pdtqual from '../../Images/PDT_Qual.png';
import compo from '../../Images/Compo.png';
import mule from '../../Images/Mule.png';
import credal from '../../Images/Credal.png';
import village from '../../Images/Groupe one.png';
import miimosa from '../../Images/miimosa.png';

const Apropos= () =>{
   
  return (
    
    <>
    <div id='Apropos'>

    <div id='PGapropos'>
    </div>

      <div id='Info'>


        <div id='Leparmentier'>
          
          <div className='Titreapropos'>
            <p>Le parmentier</p>
          </div>
          <div id='Infoparm'>
            <div id='August'>
                <img src={augustep} alt="Auguste Parmentier" />
            </div>
            <div className='Texte'>
                Apparu au XVIIIème sciècle, ce plat a herité de son nom 
                en référence à <a href='https://fr.wikipedia.org/wiki/Antoine_Parmentier' target="_blank" rel='noreferrer'>Antoine Parmentier</a><span> </span>
                pour des raisons faisant débat chez les historiens.<br/>
                Ce plat se compose habituellement de la manière suivante :<br/>
            </div>
          </div>
          <div id='Compo'>
                  <img src={compo} alt="Composition de parmentier"/>
          </div>
        </div>


        <div id='Concept'>
          <div className='Titreapropos'>
            <p>Notre concept</p>
          </div>
          <div className='Texte'>
            Il est possible d'imaginer tout un panel de variations
            de ce plat traditionnel en jouant sur de chouettes associations.<br/>
            C'est ce que <span style={{fontWeight: 'bold'}}>Purée que c'est bon!</span> a décidé de faire en
            vous proposant à chaque fois 3 bases protéinées et 4 purées de saisons.<br/>           
            <div id='IMGparm'>
              <img src={bet} width="30%" alt="Parmentier betterave"/>
              <img src={pdt} width="30%" alt="Parmentier pomme de terre"/>
              <img src={ptm} width="30%" alt="Parmentier potimarron"/>
            </div>

          </div>
        </div>
 
        <div id='Produits'>
          <div className='Titreapropos'>
            <p>Nos produits</p>
          </div>
            <div id='Infopdt'>
              <div id='PRD1'>
                  <img src={pdtqual} alt="Qualité"/>
              </div>
              <div className='Texte'>
                    Soucieux de la qualité de nos produits et de leur impact sociétal,
                    nous nous efforçons de choisir des aliments de grande qualité,
                    produits en Belgique et principalement bio.
              </div>
              <div id='PRD2'>
                  <img src={pdtqual}  alt="Qualité"/>
              </div>
          </div>
        </div>

        <div id='Partenaires'>
          <div className='Titreapropos'>
            <p>Nos partenaires</p>
          </div>

          <div id='Infopart'>
            <div className='Part'>
              <img src={village} alt="Village partenaire"/>
            </div>
            <div className='Part' >
              <img src={credal} alt="Credal"/>
            </div>
            <div className='Part'>
              <img src={mule} alt="Brasserie de la mule"/>
            </div>
            {/* <div id='Louis'>

            </div> */}
            <div className='Part'>
              <img src={miimosa} alt='Miimosa'/>
            </div>
            {/* <div id='Terroirist'>

            </div> */}
          </div>
        </div>

      </div>

        <div id='PDapropos'>
        </div>

      </div>

    </>




  );
}
export default Apropos;