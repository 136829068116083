import { useState, useEffect }  from 'react';



const useWindowSize = () =>{
    const [size,setsize]=useState([window.innerHeight,window.innerWidth]);
    useEffect (() => {
        const handleResize = () => {
            setsize([window.innerHeight,window.innerWidth])
        }
        window.addEventListener("resize",handleResize);
        return () =>{
            window.removeEventListener("resize",handleResize);
        }
    }, []);
    return size;
};

export default useWindowSize;