import React from 'react';
import {  Link } from "react-router-dom";
import './Footer.css';
import Windowsize from '../Outils/Windowsize';
import PQCB1 from '../../Images/PQCBC1.png'



const Footer = () => {
  const [WinV,WinH]=Windowsize();
  return(
    <>
    <p>{WinV} et {WinH}</p>
    <div id="Backfooter">
      <div id='Footer'>
        <div id='test'>
        <span>
        <img src={PQCB1} style={{height:20}} alt="Logo"/> |
        <Link to="/mentions">
                  <button id='Bmentions'>
                      Mentions
                  </button>
        </Link> | 
        Copyright © Purée que c'est bon! | <img src={PQCB1} style={{width:20}} alt="Logo"/>
        </span>
        </div>

      </div>
    </div>
    </>
  )
}

export default Footer;