import React  from 'react';
import Navbar from './components/Navbar/Navbar';
import Home from './components/Home/Home';
import Menu from './components/Menu/Menu';
import Apropos from './components/Apropos/Apropos';
import Event from './components/Event/Event';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import Mentions from './components/Mentions/Mentions';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';



function App() {
  return (
    <div className="container mt-3">
       
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home  />} />
          <Route path="/menu" element={<Menu  />} />
          <Route path="/apropos" element={<Apropos  />} />
          <Route path="/event" element={<Event  />} />
          <Route path="/contact" element={<Contact  />} />
          <Route path="/home" element={<Home  />} />
          <Route path="/mentions" element={<Mentions  />} />
        </Routes>
        <Footer />
      </Router>
      
    </div>
  );
}

export default App;