import React from 'react';
import './Mentions.css';

const Mentions= () =>{
    return (
        <>
            <div id='Backmentions'>
                <div id="PGmention">

                </div>
                <div id='Bandeaumentions'>
                    
                    <div id='Mentions'>
                    
                        <div id='Titremention'>
                            MENTIONS LÉGALES
                        </div>
                        
                        <div className='STmention'>
                            Propriété intellectuelle
                        </div>
                        <div className='Texte'>
                            Plutôt que de soumettre sa production au traditionnel droit de la propriété intellectuelle, fort restrictif, le "Purée que c'est bon!" opte pour l'alternative Creative Commons plus en phase avec sa philosophie de partage et de co-création.
                            Néanmoins, dans la mesure où il est juste et raisonnable de revendiquer la paternité d'une création, c’est la licence CC BY-NC-SA 4.0, qui est choisie :
                            BY – Vous devez citer le nom de l’auteur original : Purée que c'est bon!.
                            NC – Vous n’avez pas le droit d’utiliser cette création à des fins commerciales.
                            SA – Si vous modifiez, transformez ou adaptez cette création, vous n’avez le droit de distribuer la création qui en résulte que sous un contrat identique à celui-ci.
                            Licence Creative Commons. Attribution - Pas d’Utilisation Commerciale - Partage dans les Mêmes Conditions 4.0 International (CC BY-NC-SA 4.0)
                            Pour plus d'informations : <a href='https://creativecommons.org/licenses/by-nc-sa/4.0/legalcode.fr' target="_blank" rel='noreferrer'>Creative Commons</a>
                        </div>

                        <div className='STmention'>
                            Politique de protection des données personnelles
                        </div>
                        <div className='Texte'>
                            La présente Politique de protection des données personnelles décrit les méthodes que nous, 
                            "Purée que c'est bon!", utilisons pour recueillir, gérer et utiliser les informations sur les visiteurs de notre site Web. 
                            Cette Politique peut être modifiée, complétée, supprimée ou mise à jour; cependant, nous traiterons toujours vos Informations
                            personnelles conformément à la Politique en vigueur au moment de leur collecte. Notre objectif est de vous faire part 
                            régulièrement sur cette page des modifications éventuelles apportées à cette Politique, afin que vous soyez toujours 
                            pleinement informé des catégories d’informations que nous recueillons, de la manière dont nous les utilisons, et des 
                            circonstances dans lesquelles elles peuvent être communiquées. Notre Politique de protection des données se trouve sur 
                            notre page d’accueil, mais est également accessible sur chaque page où des Données personnelles sont demandées. 
                            Des explications complémentaires sur les finalités pour lesquelles les données seront utilisées, peuvent être fournies 
                            à chaque “point de collecte des données” si nécessaire.
                        </div>

                        <div className='STTmention'>
                            1.Garanties sur la confidentialité des données
                        </div>
                        <div className='Texte'>
                            Le terme “Données personnelles”, tel qu’il est utilisé dans la présente Politique, 
                            fait référence aux informations telles que vos nom, date de naissance, adresse e-mail 
                            ou numéro de téléphone, qui permettent de vous identifier. En règle générale, nous traiterons vos 
                            Données personnelles selon la procédure décrite dans cette Politique. Nous nous réservons le droit 
                            de procéder à des traitements supplémentaires qui seraient requis par la loi, ou dans le cadre d’une 
                            enquête notamment pénale.
                            Les sections suivantes expliquent les modalités de collecte de vos Données personnelles.
                        </div>

                        <div className='STTmention'>
                            2. Utilisation des Données personnelles
                        </div>
                        <div className='Texte'>
                            La plupart de nos services ne requièrent aucune forme d’inscription ou d’enregistrement, 
                            ce qui vous permet de visiter notre site sans avoir à vous identifier. Cependant, certains 
                            services peuvent nécessiter la communication de certaines Données personnelles. 
                            Dans ces cas, si vous choisissez de ne pas communiquer les informations que nous vous demandons, 
                            il se peut que vous ne puissiez pas accéder à certaines parties du site, et que nous soyons dans 
                            l’impossibilité de répondre à votre demande. Selon la réglementation locale en vigueur, 
                            vos Données personnelles nous permettent de vous fournir des produits ou services, de vous 
                            facturer les produits et services demandés, de vous proposer des produits et services susceptibles 
                            de vous intéresser ou de communiquer avec vous pour d’autres finalités évidentes compte tenu des 
                            circonstances et sur lesquelles nous vous informons lorsque nous recueillons vos Données personnelles.
                        </div>

                        <div className='STTmention'>
                            3. Non-communication des données personnelles
                        </div>
                        <div className='Texte'>
                            Vos Données personnelles ne seront jamais vendues, partagées ou communiquées à des tiers,  
                            en dehors des cas prévus par la présente Politique. Vos Données personnelles pourront être 
                            communiquées à des tiers agissant pour nous ou notre compte dans le cadre d’un traitement spécifique 
                            conformément aux finalités pour lesquelles elles ont été recueillies initialement, ou pourront être 
                            traitées légalement, selon la réglementation locale en vigueur, dans le cadre d’activités telles que 
                            fourniture de services, évaluation de l’utilité de ce site, commercialisation et marketing produit, 
                            gestion de données ou support technique. Ces tiers se seront engagés par contrat à n’utiliser vos Données 
                            personnelles qu’aux fins convenues, et à ne pas les vendre ou divulguer à d’autres tiers sauf si la loi 
                            le requiert, si nous les y autorisons explicitement, ou si une disposition est prévue en ce sens dans 
                            la présente Politique.

                            Les Données personnelles que nous recueillons pourront également être communiquées à un tiers si l’activité 
                            de tout ou partie de ce site et les données clients associées sont vendues, cédées ou transférées, 
                            auquel cas nous exigerons que l’acheteur ou le cessionnaire traitent lesdites Données personnelles 
                            conformément à la présente Politique. Par ailleurs, les Données personnelles pourront être divulguées 
                            à un tiers si nous y sommes contraints dans le cadre d’une loi en ou d’une disposition réglementaire 
                            vigueur, d’une ordonnance judiciaire ou d’une réglementation gouvernementale, ou si cette divulgation 
                            est nécessaire dans le cadre d’une enquête, ou d’une procédure pénale, sur le territoire national ou à 
                            l’étranger.
                        </div>

                        <div className='STTmention'>
                            4. Droit d’accès, de rectification et d’opposition
                        </div>
                        <div className='Texte'>
                            Chaque fois que nous traitons des Données personnelles, nous prenons toutes les mesures raisonnables pour nous assurer 
                            de l’exactitude et de la pertinence de vos données conformément aux finalités pour lesquelles nous les collectons. 
                            Vous disposez d’un droit d’opposition au traitement de vos Données personnelles pour des raisons légitimes, 
                            c’est-à-dire si ce traitement n’est pas raisonnablement nécessaire à la poursuite de notre intérêt légitime tel que 
                            décrit dans la présente Politique, ou au respect de la loi. En cas de prospection y compris par voie électronique, 
                            nous vous fournirons les moyens vous permettant de vous opposer à recevoir des informations ou d’y consentir, 
                            conformément à la loi. Si vous souhaitez savoir comment nous utilisons vos Données personnelles, ou vous opposer 
                            au traitement de celles-ci, contactez-nous par e-mail à l’adresse suivante: info@pureequecestbon.be. 
                            Dans ce cas, veuillez indiquer le nom du site Web sur lequel vous avez communiqué vos informations, 
                            ainsi que celles que vous souhaiteriez que nous corrigions, mettions à jour ou supprimions, en vous identifiant 
                            de manière précise. Les demandes de suppression de données personnelles seront soumises aux obligations qui nous 
                            sont imposées par la loi, notamment en matière de conservation ou d’archivage des documents.
                        </div>

                        <div className='STTmention'>
                            5. Sécurité et confidentialité
                        </div>
                        <div className='Texte'>
                            Pour assurer la sécurité et la confidentialité des Données personnelles que nous recueillons en ligne, 
                            nous utilisons des réseaux protégés par des dispositifs standards tels que par pare-feu et mot de passe.
                            Lors du traitement de vos Données personnelles, nous prenons toutes les mesures raisonnables visant à les 
                            protéger contre toute perte, utilisation détournée, accès non autorisé, divulgation, altération ou destruction.
                        </div>

                        <div className='STTmention'>
                            6. “Cookies” et balises (tags) Internet
                        </div>
                        <div className='Texte'>
                            Nous sommes susceptibles de recueillir et traiter les informations concernant votre visite de ce site Web,
                            telles que les pages consultées, le site Web visité juste avant celui-ci, et les recherches effectuées.
                            Ces informations nous permettent d’améliorer le contenu du site, et de compiler des statistiques sur 
                            les individus utilisant notre site à des fins d’étude de marché interne. 
                            Ce faisant, nous pourrons être amenés à installer des “cookies” collectant le nom de domaine de 
                            l’utilisateur, votre fournisseur d’accès Internet, votre système d’exploitation, 
                            ainsi que la date et l’heure d’accès. Un “cookie” est un petit fichier d’information envoyé 
                            sur votre navigateur et enregistré sur le disque dur de votre ordinateur. 
                            Les cookies ne risquent en aucun cas d’endommager votre PC. Vous pouvez configurer votre navigateur 
                            pour qu’il vous avertisse lors de la réception d’un “cookie”, afin de vous permettre de décider 
                            si vous souhaitez ou non l’accepter. Si vous ne souhaitez pas que nous installions de cookie, 
                            contactez-nous par e-mail à l’adresse suivante: info@pureequecestbon.be. Sachez cependant que dans ce cas, 
                            il se peut que les fonctionnalités de votre logiciel de navigation ne soient pas toutes disponibles. 
                            Nous pourrons en outre faire appel aux services de prestataires externes pour nous aider à recueillir 
                            et traiter les informations décrites dans cette section.
                        </div>

                        <div className='STTmention'>
                            7. Informations personnelles et enfants 
                        </div>
                        <div className='Texte'>
                            Nous ne collecterons, n’utiliserons et ne divulguerons pas volontairement de Données personnelles 
                            communiquées par un mineur de moins de 18 ans, sans obtenir le consentement préalable de son responsable 
                            légal (parent ou tuteur) via un contact direct hors ligne. Nous informerons ce responsable (i) 
                            des catégories spécifiques de données personnelles recueillies auprès de la personne mineure, 
                            et (ii) de la possibilité de s’opposer à la collecte, l’utilisation ou la conservation de celles-ci.
                            Nous respectons scrupuleusement les lois visant à protéger les enfants.
                        </div>

                        <div className='STTmention'>
                            8. Liens vers d’autres sites 
                        </div>
                        <div className='Texte'>
                            La présente Politique de protection des données s’applique uniquement à ce site, 
                            et non pas aux sites Web détenus par des tiers. Nous donnons parfois des liens vers d’autres sites Web 
                            que nous jugeons susceptibles d’intéresser nos visiteurs. Nous nous assurons dans ce cas que ces sites satisfont 
                            aux critères les plus élevés. Cependant du fait de la nature même d’Internet, nous ne pouvons garantir les règles 
                            de confidentialité et de protection des données personnelles des sites Web pour lesquels nous fournissons un lien
                            d’accès et ne pouvons être tenus responsables du contenu de sites autres que celui-ci, la présente Politique 
                            n’étant pas applicable aux sites non-Novartis.
                        </div>

                        <div className='STTmention'>
                            9. Comment nous contacter
                        </div>
                        <div className='Texte'>
                            Si vous avez des questions ou des réclamations concernant notre conformité à la présente 
                            Politique de confidentialité des données, ou si vous souhaitez nous faire part de recommandations 
                            ou des commentaires visant à améliorer sa qualité, contactez-nous par e-mail à 
                            l’adresse suivante: info@pureequecestbon.be
                        </div>

                            
                        </div>

                    </div>

                <div id="PDmention">
                </div>
            </div>
        </>
    )

}
export default Mentions;